<template>
  <div class="container-fluid">
    <all-transactions-list ref="alltransactionList" />
  </div>
</template>

<script>
import Vue from "./main";
import AllTransactionsList from "./components/lists/AllTransactionsList.vue";
export default {
  name: "ElixioWalletTransactionsManager",
  components: {
    AllTransactionsList,
  },
  data() {
    return {

    };
  },
  created() {

  },
};
</script>
